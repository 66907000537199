// @flow

/*
 * env.js
 * These are app environment constants such as where our backend is located, what
 * version of the API we are using, etc
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YOUR_ACTION_CONSTANT';
 */

const apiUrls = {
    development: 'http://0.0.0.0:8000',
    dev: 'https://api-dot-ts-sag-dev.appspot.com',
    staging: 'https://api-dot-ts-sag-test.appspot.com',
    production: 'https://api-dot-ts-sag-prod.appspot.com',
};

const devConfig = {
    apiKey: 'AIzaSyBQTbfAFwdEvQOynBLHtTS3OIkCpsVKB6s',
    authDomain: 'ts-sag-dev.firebaseapp.com',
    databaseURL: 'https://ts-sag-dev.firebaseio.com',
    projectId: 'ts-sag-dev',
};

const prodConfig = {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
};

export const API_URL = process.env.NODE_ENV ? apiUrls[process.env.NODE_ENV] : 'http://0.0.0.0:8000';
export const API_VER = '/api/';
// Local DEV and staging environments will refer to firebase development environment, production environment will be the sole to use live firebase environment
export const firebaseConfig = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

export const gaTrackingId = '';
